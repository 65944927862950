import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (studentId, year, month) {
    try {
        const params = { studentId, year, month };
        const res = await axios.get(Env.apiPath + "listStudentSession", { params });
        if (res.data.error) {
            return [null, res.data.error];
        }
        return [res.data.list, res.data.error];
    } catch (err) {
        return [null, err];
    }
}