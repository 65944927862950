<template>
<div>
    <van-calendar title="打卡" :poppable="false" :style="{ height: '500px' }" row-height="64" color="#1989fa" type="multiple" @select="selectDate" @unselect="unselectDate" :formatter="formatter" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" ref="cal" />
    <van-action-sheet v-model:show="showPicker" :actions="pickerActions" @select="onPick" @closed="closePicker" description="选择打卡次数" cancel-text="取消" />
</div>
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    Calendar,
    ActionSheet
} from "vant";

export default {
    components: {
        [Calendar.name]: Calendar,
        [ActionSheet.name]: ActionSheet
    },
    props: {
        monthRecords: Object,
        nowYear: Number,
        nowMonth: Number, //1...12
        nowDate: Number
    },
    setup(props, {
        emit
    }) {
        const minDate = new Date(props.nowYear, props.nowMonth - 1, 1);
        const maxDate = new Date(props.nowYear, props.nowMonth, 0);

        const cal = ref(null);
        const monthItem = props.monthRecords ? JSON.parse(JSON.stringify(props.monthRecords)) : {};

        function resetCalendar() {
            const keys = Object.keys(monthItem);
            if (keys.length) {
                cal.value.reset(keys.map(function (a) {
                    return new Date(props.nowYear, props.nowMonth - 1, a);
                }));
            } else {
                cal.value.reset();
            }
        }

        onMounted(function () {
            resetCalendar();
        });

        let selectedDay = null;
        const selectDate = function (date) {
            selectedDay = date[date.length - 1].getDate().toString();
            showPicker.value = true;
        };

        const unselectDate = function (date) {
            selectedDay = null;
            delete monthItem[date.getDate().toString()];
        };

        const showPicker = ref(false);
        const pickerActions = [{
                name: "半次",
                value: 1
            },
            {
                name: "一次",
                value: 2
            }
        ];
        const closePicker = function () {
            resetCalendar();
        };
        const onPick = function (item) {
            monthItem[selectedDay] = item.value;
            showPicker.value = false;
        };

        const formatter = (day) => {
            const month = day.date.getMonth();
            const date = day.date.getDate();
            if (month + 1 === props.nowMonth && date === props.nowDate) {
                day.text = "今天";
                day.className = "bg-green-300";
            }
            if (monthItem[date.toString()] === 1) {
                day.bottomInfo = "💃";
                day.className = "bg-blue-500 rounded-full";
            } else if (monthItem[date.toString()] === 2) {
                day.bottomInfo = "💃💃";
                day.className = "bg-blue-700 rounded-full";
            }
            return day;
        };

        const onConfirm = function () {
            emit("done", JSON.stringify(monthItem));
        };

        return {
            selectDate,
            unselectDate,
            formatter,
            minDate,
            maxDate,
            onConfirm,
            showPicker,
            pickerActions,
            onPick,
            closePicker,
            cal
        };
    }
}
</script>
