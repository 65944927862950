import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (userId, year, month) {
    try {
        const params = { userId, year, month };
        const res = await axios.get(Env.apiPath + "loadPractice", { params });
        return [res.data.record, res.data.error];
    } catch (err) {
        return [null, err];
    }
}