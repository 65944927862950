import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (year, month, studentId, all) {
    try {
        const toSend = new FormData();
        toSend.append("year", year);
        toSend.append("month", month);
        toSend.append("studentId", studentId);
        toSend.append("all", all);
        const res = await axios.post(Env.apiPath + "listStudentClass", toSend);
        return [res.data.list, res.data.error];
    } catch (err) {
        return [null, err];
    }
}