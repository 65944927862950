import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (userId, year, month, records) {
    try {
        const toSend = new FormData();
        toSend.append("userId", userId);
        toSend.append("year", year);
        toSend.append("month", month);
        toSend.append("records", records);

        const res = await axios.post(Env.apiPath + "savePractice", toSend);
        if (res.data.error) {
            alert(res.data.error);
            return null;
        }
        return true;
    } catch (err) {
        alert(err);
    }
}