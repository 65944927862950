<template>
    <div class="pt-32 flex flex-wrap">
        <div class="md:w-1/3 w-full mb-12">
            <van-cell-group class="shadow-md " inset v-if="student">
                <profile-cell :user="student" :lang-code="'zh'" :show-credit="true" />
                <van-cell>
                    <template #value>
                        <div class="flex flex-row-reverse flex-wrap" v-if="student.active && studentClass.length">
                            <van-button v-if="showDays" class="ml-4 mb-2" plain type="warning" size="small"
                                @click="showDays = false">取消打卡</van-button>
                            <van-button v-if="!showDays" class="ml-4 mb-2" plain type="primary" size="small"
                                :loading="loading" @click="showMonthList = true">管理打卡</van-button>
                        </div>
                    </template>
                </van-cell>
                <student-day class="mb-4" :month-records="monthRecords" :nowYear="pickedYear" :nowMonth="pickedMonth"
                    :nowDate="pickedDate" v-if="showDays" @done="sendRecords($event)" />
            </van-cell-group>
        </div>
        <div class="md:w-2/3 w-full flex flex-wrap px-4 justify-evenly items-start">
            <div class="md:w-5/12 w-full">
                <van-cell-group class="shadow-md mx-0 mb-4" inset>
                    <van-cell :title="year + '年' + month + '月'" />
                    <van-cell title="本月已出席课时" :value="totalHours + '小时'" />
                    <van-cell title="本月出席次数" :value="totalAttend" />
                    <van-cell title="本月迟到次数" :value="totalLate" />
                    <van-cell title="本月请假次数" :value="totalAbsent" />
                    <van-cell title="本月旷课次数" :value="totalDisappear" />
                </van-cell-group>
                <van-cell-group class="shadow-md mx-0" inset v-if="studentClass.length">
                    <van-cell title="学生课程" />
                    <van-cell v-for="(c, index) in studentClass" :key="index" :title="c.className" :label="c.datesText"
                        is-link @click="clickClass(c)">
                        <template #value>
                            <van-tag size="medium" type="success" v-if="c.valid">已确认录取</van-tag>
                            <van-tag size="medium" type="warning" v-else>等待录取</van-tag>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>

            <div class="h-8">&nbsp;</div>
            <van-cell-group class="md:w-5/12 w-full shadow-md m-0" inset>
                <van-cell v-for="(session, index) in sessions" :key="index" :title="classNames[session.cid]"
                    :label="session.timeText">
                    <template #value>
                        <div class="text-green-400" v-if="session.check[studentId].a === 1">出勤</div>
                        <div class="text-yellow-600" v-else-if="session.check[studentId].a === 2">迟到</div>
                        <div class="text-red-600" v-else-if="session.check[studentId].a === 3">请假</div>
                        <div class="text-gray-800" v-else-if="session.check[studentId].a === 4">旷课</div>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
    </div>
    <van-action-sheet v-model:show="showMonthList" :actions="actionsMonth" @select="pickMonth($event)"
        description="选择打卡月份" cancel-text="取消" />
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    CellGroup,
    Cell,
    Button,
    ActionSheet,
    Tag,
    Notify
} from "vant";
import ProfileCell from "@/components/ProfileCell.vue";
import StudentDay from "@/components/StudentDay.vue";
import GetSession from "@/asyncs/GetSession.js";
import ViewUser from "@/asyncs/ViewUser.js";
import LoadPractice from "@/asyncs/LoadPractice.js";
import SavePractice from "@/asyncs/SavePractice.js";
import ListSession from "@/asyncs/ListStudentSession.js";
import ListProp from "@/asyncs/ListClassProp.js";
import ListStudentClass from "@/asyncs/ListStudentClass.js";
import Helpers from "@/logics/Helpers.js";
import StaticDays from "@/static_values/StaticDays.js";
import UserTypes from "@/static_values/StaticUserTypes.js";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Button.name]: Button,
        [ActionSheet.name]: ActionSheet,
        [Tag.name]: Tag,
        ProfileCell,
        StudentDay
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "学生管理");
        const route = useRoute();
        const router = useRouter();
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const student = ref(null);
        const monthRecords = ref(null);
        const showDays = ref(false);
        const loading = ref(false);
        const sessions = ref([]);
        const classNames = ref({});
        const totalAttend = ref(0);
        const totalLate = ref(0);
        const totalAbsent = ref(0);
        const totalDisappear = ref(0);
        const totalHours = ref(0);
        const studentClass = ref([]);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                if (!store.state.user) {
                    const ok = await GetSession();
                    if (!ok) {
                        return;
                    }
                }
                if (store.state.user.userType === UserTypes.student.value && store.state.user.userId != route.params.studentId) {
                    Notify({
                        type: "danger",
                        message: "You cannot view other student info",
                        duration: 5000
                    });
                    return;
                }
                student.value = await ViewUser(route.params.studentId);
                if (!student.value) {
                    return;
                }
                const listSession = await ListSession(route.params.studentId, year, month);
                const ids = new Set();
                listSession[0].forEach(function (session) {
                    ids.add(session.cid);
                    session.timeText = Helpers.makeDateText(session.y, session.m, session.d) + " " + StaticDays[session.wd]["zh"] + " " + Helpers.makeClassTimeToText(session.t);

                    if (session.m !== month) {
                        return;
                    }
                    if (session.du) {
                        if (session.check[route.params.studentId].a === 1 ||
                            session.check[route.params.studentId].a === 2) {
                            totalHours.value += session.du;
                        }
                    }
                    if (session.check[route.params.studentId].a === 1) {
                        totalAttend.value += 1;
                    } else if (session.check[route.params.studentId].a === 2) {
                        totalLate.value += 1;
                    } else if (session.check[route.params.studentId].a === 3) {
                        totalAbsent.value += 1;
                    } else if (session.check[route.params.studentId].a === 4) {
                        totalDisappear.value += 1;
                    }
                });
                totalHours.value = (totalHours.value / 60).toFixed(1);
                if (ids.size) {
                    classNames.value = await ListProp(Array.from(ids), "className");
                }
                sessions.value = listSession[0];

                if (store.state.user.userType !== UserTypes.student.value) {
                    const sc = await ListStudentClass(year, month, route.params.studentId, true);
                    if (sc[1]) {
                        alert(sc[1]);
                        return;
                    }
                    sc[0].forEach(function (c) {
                        c.datesText = Helpers.makeDateRange(c.classDates.start, c.classDates.end, "zh");
                        studentClass.value.push(c);
                    });
                }
            })
        });
        const pickedYear = year;
        const pickedMonth = ref(null);
        const pickedDate = ref(null);
        const showMonthList = ref(false);
        const actionsMonth = ref([]);
        let m = month;
        while (m > 0) {
            actionsMonth.value.push({
                name: m + "月",
                month: m
            });
            m -= 1;
        }
        const pickMonth = function (item) {
            showMonthList.value = false;

            pickedMonth.value = item.month;
            if (pickedMonth.value === month) {
                pickedDate.value = today.getDate();
            } else {
                pickedDate.value = null;
            }
            showRecords(pickedMonth.value);
        };
        const showRecords = async function (month) {
            monthRecords.value = null;
            loading.value = true;
            const record = await LoadPractice(route.params.studentId, year, month);
            if (record[0]) {
                if (record[0]["practice"][year]) {
                    if (record[0]["practice"][year][month]) {
                        monthRecords.value = record[0]["practice"][year][month];
                    }
                }
            }
            showDays.value = true;
            loading.value = false;
        };
        const sendRecords = function (json) {
            (async () => {
                const ok = await SavePractice(route.params.studentId, year, pickedMonth.value, json);
                if (ok) {
                    showDays.value = false;
                } else {
                    alert("更新学生记录失败！");
                }
            })();
        };
        const clickClass = function (c) {
            router.push("/classStudents/" + c.classId);
        };
        return {
            student,
            monthRecords,
            showDays,
            loading,
            pickedYear,
            pickedMonth,
            pickedDate,
            showMonthList,
            actionsMonth,
            pickMonth,
            sendRecords,
            sessions,
            classNames,
            studentId: ref(route.params.studentId),
            totalAttend,
            totalLate,
            totalAbsent,
            totalDisappear,
            totalHours,
            month,
            year,
            studentClass,
            clickClass
        };
    }
};
</script>
